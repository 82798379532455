import axios from 'axios';
import { Device, Order } from '../../types/schema';

export async function sendNotificationToDevice( order: Order, cloverDevice?: Device ) {
	return await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postDeviceNotification`, {
		deviceId: cloverDevice.externalId,
		event   : 'pay-order-notification',
		dataBody: {
			title  : 'Pay for an Order',
			body   : 'Your order is ready to be paid.',
			orderId: order.externalId,
		},
	} );
}
