import AsyncLoadingButton, { LoadingButtonProps } from '@/components/form/asyncLoading/asyncLoadingButton';
import StopIcon from '@mui/icons-material/Stop';
import { Button, ButtonGroup } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useAsyncEffect } from 'rooks';

export default function MultiAsyncLoadingButton( {
	children,
	loadFunction,
	start,
	onFinish,
	onClick,
	gatewayType,
	...props
}: {
	loadFunction: ( page: number ) => Promise<true | number | string>,
	start?: boolean,
	gatewayType?: string,
	onFinish?: () => void
} & LoadingButtonProps ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const [ loading, setLoading ] = useState( false );
	const [ page, setPage ] = useState( 0 );
	
	useEffect( () => {
		if ( !start ) return;
		setLoading( true );
		setPage( 0 );
	}, [ start ] );
	
	useAsyncEffect( async () => {
		if ( !loading ) return;
		try {
			const status = await loadFunction( page );
			if ( !status || status === true ) {
				setLoading( false );
				onFinish?.();
				return;
			}
			setPage( page + 1 );
		} catch ( e ) {
			console.error( e );
			enqueueSnackbar( String( e?.response?.data.message || e?.response?.data || e?.message || e ), { variant: 'error' } );
			if ( gatewayType === 'QB' ) {
				enqueueSnackbar( 'Please try the sync manually; press the buttons that did not finish the sync process. quickbooks.intuit.com', { variant: 'error' } );
			} else {
				enqueueSnackbar( 'Please try the sync manually; press the buttons that did not finish the sync process. Clover.com', { variant: 'error' } );
			}
			setLoading( false );
		}
	}, [ page, loading ] );
	
	return (
		<ButtonGroup>
			<AsyncLoadingButton
				loading={loading}
				loadingPosition='start'
				onClick={async ( arg ) => {
					setLoading( true );
					setPage( 0 );
					await onClick?.( arg );
				}}
				{...props}>
				{loading ? `Page ${page}` : children}
			</AsyncLoadingButton>
			{loading && (
				<Button
					color='error'
					onClick={() => {
						setLoading( false );
					}}>
					<StopIcon/>
				</Button>
			)}
		</ButtonGroup>
	);
}
