import Form from '@/components/form';
import MultiAsyncLoadingButton from '@/components/multiAsyncLoadingButton';
import { mutateGraphQL } from '@/data/apollo';
import { DevicesWrite } from '@/data/device.graphql';
import { convertCloverDevice } from '@/gatewayUtils/cloverUtils';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { MutationDevicesWriteArgs } from '@/types/schema';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Divider, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import LocationSelect from '../../../formSelects/locationSelect';

export default function CloverDeviceModal( { onSubmit }: { onSubmit: () => void } ) {
	const [ sync, setSync ] = useState( 0 );
	const [ disable, setDisable ] = useState( false );
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	
	return (
		<ResponsiveModalContainer title='Sync devices from Clover'>
			<Form
				initialValues={{ location: null }}
				onSubmit={() => null}>
				{( formik ) => {
					const location = formik.values.location;
					
					return (
						<Fragment>
							<LocationSelect
								onlyGateway
								name='location'
								label='Clover Location'
								onAdd={undefined}
							/>
							{location && (
								<Fragment>
									<Divider sx={{ mt: 2 }}/>
									<ListItem>
										<ListItemText
											primary='Sync Devices'
											primaryTypographyProps={{ variant: 'h5' }}
										/>
										<MultiAsyncLoadingButton
											variant='contained'
											color='primary'
											startIcon={<ReceiptIcon/>}
											disabled={disable}
											loadFunction={async ( page ) => {
												const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/getDevices`, {
													id: location.gateway.id,
												} );
												const input = data.data.map( ( device ) => convertCloverDevice( location.gateway.id, device ) )
													.filter( Boolean );
												if ( !input.length ) return true;
												const result = await mutateGraphQL<MutationDevicesWriteArgs>( {
													mutation : DevicesWrite,
													variables: { inputs: input },
												} );
												onSubmit();
												if ( result ) {
													enqueueSnackbar( 'Devices imported successfully.', { variant: 'success' } );
													closeModal();
												}
												return true;
											}}
											start={sync === 5}
											onFinish={async () => {
												if ( sync ) setSync( 6 );
												setDisable( true );
											}}>
											Sync Devices
										</MultiAsyncLoadingButton>
									</ListItem>
								</Fragment>
							)}
						</Fragment>
					);
				}}
			</Form>
		</ResponsiveModalContainer>
	);
}
